import { addDays, differenceInDays, format, parse } from "date-fns";

const FORMAT = "yyyy-MM-dd";

const getSalesData = (res) => {
  const from = parse(res.from, "yyyy-MM-dd", new Date());
  const to = parse(res.to, "yyyy-MM-dd", new Date());
  const pastFrom = parse(res.past_from, "yyyy-MM-dd", new Date());

  const days = differenceInDays(to, from);

  let data = {
    chartData: [],
    y1Max: 0,
    y2Max: 0,
  };

  for (let i = 0; i <= days; i++) {
    const currentDate = format(addDays(from, i), FORMAT);
    const pastDate = format(addDays(pastFrom, i), FORMAT);

    let saleAtDate = 0;
    let saleAtPastDate = 0;
    let customersAtDate = 0;
    let pastCustomersAtDate = 0;

    if (res.sales[currentDate]) {
      saleAtDate = res.sales[currentDate].total.toFixed(2) || 0;
      customersAtDate = res.sales[currentDate].customers;
    }

    if (res.pastSales[pastDate]) {
      saleAtPastDate = res.pastSales[pastDate].total.toFixed(2) || 0;
      pastCustomersAtDate = res.pastSales[pastDate].customers;
    }

    data.chartData.push({
      name: currentDate,
      date: currentDate,
      pastDate: pastDate,
      saleAtDate,
      saleAtPastDate,
      value: saleAtDate,
      customersAtDate,
      pastCustomersAtDate,
    });

    if (data.y1Max < Math.max(saleAtDate, saleAtPastDate)) {
      data.y1Max = Math.max(saleAtDate, saleAtPastDate);
    }
    if (data.y2Max < Math.max(customersAtDate, pastCustomersAtDate)) {
      data.y2Max = Math.max(customersAtDate, pastCustomersAtDate);
    }
  }

  return data;
};

export default getSalesData;
