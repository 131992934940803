import { useState } from "react";

import Validate from "./Validate";
import LoginForm from "./LoginForm";

const LoginPage = () => {
  const [tokenFromLocal] = useState(localStorage.getItem("accessToken"));

  const [isGuest, setIsGuest] = useState(false);

  if (isGuest) {
    return <LoginForm />;
  }

  return (
    <Validate token={tokenFromLocal} setIsGuest={() => setIsGuest(true)} />
  );
};

export default LoginPage;
