import axios from "axios";

const request = (slug, data = {}, method = "post") => {
  const url = `${process.env.REACT_APP_SERVER_URL}/${slug}`;

  // get the bearer token from localstorage
  let bearerToken = localStorage.getItem("accessToken") || null;
  const headers = {
    Accept: "application/json",
    "Content-Type": "application/json",
    Authorization: `Bearer ${bearerToken}`,
  };

  return axios({
    method,
    url,
    headers,
    data,
  })
    .then((r) => r.data)
    .catch((e) => e);
};

export default request;
