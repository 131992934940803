import { useEffect, useState } from "react";
import request from "../../../services/request";
import get from "lodash/get";
import currencyFormat from "../../../utils/currencyFormat";

const CustomersPage = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchCustomers();
  }, []);

  const fetchCustomers = () => {
    console.log("fetching customers");
    request("customers/summary")
      .then((res) => {
        const customers = get(res, "customers", []) || [];
        setData(
          customers.sort(function (b, a) {
            return a.total - b.total;
          })
        );
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="bg-white">
            <table className="table">
              <thead>
                <tr>
                  <th>Name</th>
                  <th>Email</th>
                  <th>Phone</th>
                  <th>Purchases (Last 30 days)</th>
                </tr>
              </thead>
              <tbody>
                {data.map(({ name, phone, email, total }) => {
                  return (
                    <tr key={phone + email}>
                      <td>{name}</td>
                      <td>{email}</td>
                      <td>{phone}</td>
                      <td>{currencyFormat(total)}</td>
                    </tr>
                  );
                })}
              </tbody>
            </table>
          </div>
        </div>
      </div>
    </div>
  );
};

export default CustomersPage;
