import * as s from "./login.module.scss";

const LoginLayout = ({ children }) => {
  return (
    <div className="container mt-5 pt-5">
      <div className="row justify-content-center">
        <div className="col-sm-10 col-md-8 col-lg-6">
          <div className={s.login_form}>{children}</div>
        </div>
      </div>
    </div>
  );
};

export default LoginLayout;
