import { format } from "date-fns";

const columns = [
  {
    name: "Invoice #",
    id: "invoice_id",
    selector: (row) => row.invoice_id,
    sortable: true,
  },
  {
    name: "Store",
    id: "name",
    selector: (row) => row.store.name,
    sortable: true,
  },

  {
    name: "Amount",
    id: "amount",
    selector: (row) => row.total / 100,
    sortable: true,
  },
  {
    name: "Payment Method",
    id: "payment_method",
    selector: (row) => row.payment_method,
    sortable: true,
  },
  {
    name: "Created at",
    id: "created_at",
    selector: (row) => format(new Date(row.created_at), "PPpp"),
    sortable: true,
    sortFunction: (rowA, rowB) =>
      new Date(rowA.created_at).getTime() - new Date(rowB.created_at).getTime(),
  },
];

export default columns;
