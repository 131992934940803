import DataTable from "react-data-table-component";

const Datatable = (props) => {
  return <DataTable pagination customStyles={customStyles} {...props} />;
};

export default Datatable;

const customStyles = {
  headCells: {
    style: {
      fontSize: "16px",
    },
  },
  cells: {
    style: {
      fontSize: "16px",
    },
  },
};
