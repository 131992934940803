import Form from "react-bootstrap/Form";

const Select = ({
  value = "",
  id = "",
  setValue = () => null,
  options = [],
  label = "",
  helpText = "",
}) => {
  return (
    <Form.Group className="mb-3" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Select
        aria-label={id}
        value={value}
        onChange={(e) => setValue(id, e.target.value)}
      >
        <option disabled value={""}>
          Select
        </option>
        {options.map(({ label, value }, idx) => (
          <option value={value} key={idx}>
            {label}
          </option>
        ))}
      </Form.Select>
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default Select;
