import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const columns = (handleShow = () => null) => [
  {
    name: "Name",
    id: "name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Category",
    selector: (row) => row.category.name,
    sortable: true,
  },
  {
    name: "Buying Price",
    selector: (row) => row.buying_price / 100,
    sortable: true,
  },
  {
    name: "Selling Price",
    selector: (row) => row.selling_price / 100,
    sortable: true,
  },
  {
    name: "Visible in Web",
    selector: (row) =>
      row.image && row.web_visible ? (
        <AiOutlineCheck className={"text-success"} />
      ) : (
        <AiOutlineClose className={"text-danger"} />
      ),
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = rowA.image && rowA.web_visible;
      const b = rowB.image && rowB.web_visible;
      if (a > b) {
        return 1;
      } else {
        return -1;
      }
    },
  },
  {
    name: "",
    selector: (row) => (
      <button
        className={"btn btn-sm btn-light"}
        onClick={() => handleShow(row)}
      >
        Edit
      </button>
    ),
    sortable: false,
  },
];

export default columns;
