const Mn = 1000000;

const currencyFormat = (valueInCents = 0, format = false) => {
  if (!valueInCents && valueInCents !== 0) return "N/A";
  const value = valueInCents / 100;
  if (isNaN(Number(value))) return "N/A";
  const isValidMn = value > Mn && format;
  let val = value;
  if (format) {
    val = value > 10000 ? Math.round(value) : value;
  }
  if (isValidMn) {
    val = value / Mn;
  }
  const currency = Number(val).toLocaleString("ta-LK", {
    style: "currency",
    currency: "LKR",
  });
  if (isValidMn) {
    return `${currency} Mn`;
  }
  if (format) {
    return currency.toString().slice(0, -3);
  }
  return currency;
};

export default currencyFormat;
