import { combineReducers } from "redux";
import userReducer from "./UserStore/reducer";
import companyReducer from "./CompanyStore/reducer";
import invoiceReducer from "./InvoiceStore/reducer";
import productReducer from "./ProductStore/reducer";

const allReducers = combineReducers({
  user: userReducer,
  company: companyReducer,
  invoices: invoiceReducer,
  products: productReducer,
});

export default allReducers;
