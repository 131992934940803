import { SET_SUMMARY } from "./types";

export const initialState = {
  salesSummary: {},
};

const companyReducer = (state = initialState, action) => {
  switch (action.type) {
    case SET_SUMMARY:
      return {
        ...state,
        salesSummary: action.salesSummary,
      };
    default:
      return { ...state };
  }
};

export default companyReducer;
