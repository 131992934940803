import { useEffect, useState } from "react";
import request from "../../../services/request";
import { parseISO, format } from "date-fns";
import Datatable from "../../../components/Datatable";
import columns from "./data/columns";

const InvoicesPage = () => {
  const [invoices, setInvoices] = useState([]);
  useEffect(() => {
    request("invoices/all")
      .then((res) => {
        setInvoices(
          res
            .sort(function (a, b) {
              return new Date(b.created_at) - new Date(a.created_at);
            })
            .slice(0, 200)
        );
      })
      .catch((e) => {
        console.log(e);
      });
  }, []);

  return (
    <div className={"card"}>
      <Datatable
        title={"Invoices"}
        columns={columns}
        data={invoices}
        defaultSortFieldId={"created_at"}
        defaultSortAsc={false}
        progressPending={invoices.length === 0}
      />
    </div>
  );
};

export default InvoicesPage;
