import { useEffect, useState } from "react";
import request from "../../../services/request";
import columns from "./data/columns";
import Datatable from "../../../components/Datatable";
import ProductModal from "./ProductModal";
import { AiOutlinePlus, AiOutlineClose } from "react-icons/ai";
import { MdOutlineClose } from "react-icons/md";
import { InputGroup, Button, FormControl } from "react-bootstrap";

const ProductsPage = () => {
  const [allProducts, setAp] = useState([]);

  const [products, setProducts] = useState([]);

  const [product, setProduct] = useState({});

  const [show, setShow] = useState(false);

  const [searchStr, setSearchStr] = useState("");

  const handleClose = () => {
    setProduct(null);
    setShow(false);
  };
  const handleShow = (product = {}) => {
    setProduct(product);
    setShow(true);
  };

  const fetchProducts = () => {
    setProducts([]);
    setSearchStr("");
    request("products/all")
      .then((res) => {
        setAp(res);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchProducts();
  }, []);

  useEffect(() => {
    setProducts(allProducts);
  }, [allProducts]);

  useEffect(() => {
    if (!searchStr) {
      setProducts(allProducts);
      return;
    }
    if (searchStr.length < 3) return;

    setProducts(
      allProducts.filter(
        (product) =>
          product.name &&
          product.name.toLowerCase().indexOf(searchStr.toLowerCase()) > -1
      )
    );
  }, [searchStr]);

  return (
    <div className={"card"}>
      <div className="p-3">
        <div className="row">
          <div className="col-sm-6 mb-3">
            <button
              className="btn btn-primary float-right"
              onClick={handleShow}
            >
              <AiOutlinePlus />
              Add Product
            </button>
          </div>

          <div className="col-sm-6">
            <InputGroup>
              <FormControl
                placeholder="Search"
                aria-label="Search"
                aria-describedby="search"
                onChange={(e) => setSearchStr(e.target.value)}
                value={searchStr}
              />
              <Button
                variant="outline-danger"
                id="search"
                onClick={() => setSearchStr("")}
              >
                <MdOutlineClose size={"20px"} />
              </Button>
            </InputGroup>
          </div>
        </div>
      </div>

      <Datatable
        title={"Products"}
        columns={columns(handleShow)}
        data={products}
        progressPending={products.length === 0}
      />

      <ProductModal
        product={product}
        show={show}
        close={handleClose}
        setProducts={setProducts}
        refresh={fetchProducts}
      />
    </div>
  );
};

export default ProductsPage;
