import { useEffect, useState } from "react";
import request from "../../../services/request";
import getChartData from "./getChartData";
import Chart from "./Chart";

const WebOrdersSummaryPage = () => {
  const [data, setData] = useState([]);
  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    console.log("fetching customers");
    request("web-orders/summary")
      .then((res) => {
        setData(getChartData(res));
      })
      .catch((e) => {
        console.log(e);
      });
  };

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="bg-white">
            <Chart data={data} />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebOrdersSummaryPage;
