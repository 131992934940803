import { AiOutlineCheck, AiOutlineClose } from "react-icons/ai";

const columns = [
  {
    name: "Product",
    id: "name",
    selector: (row) => row.name,
    sortable: true,
  },
  {
    name: "Category",
    id: "category",
    selector: (row) => row.category,
    sortable: true,
  },
  {
    name: "Quantity",
    id: "qty",
    selector: (row) => row.qty.toFixed(3),
    sortable: true,
  },
  {
    name: "Sale",
    id: "sale",
    selector: (row) => row.total / 100,
    sortable: true,
  },
  {
    name: "Visible in Web",
    id: "web_visible",
    selector: (row) =>
      row.web_visible && row.web_visible === "Yes" ? (
        <AiOutlineCheck className={"text-success"} />
      ) : (
        <AiOutlineClose className={"text-danger"} />
      ),
    sortable: true,
    sortFunction: (rowA, rowB) => {
      const a = rowA.web_visible === "Yes";
      const b = rowB.web_visible === "Yes";
      if (a > b) {
        return 1;
      } else {
        return -1;
      }
    },
  },
];

export default columns;
