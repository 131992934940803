import { useEffect, useState } from "react";
import request from "services/request";
import CardGrid from "components/CardGrid";
import { setSummary } from "Stores/CompanyStore/actions";
import { useDispatch, useSelector } from "react-redux";
import { format, subDays } from "date-fns";
import LineChart from "components/LineChart";
import DatePicker from "react-datepicker";
import * as s from "./index.module.scss";
import Datatable from "components/Datatable";
import columns from "./data/columns";
import getSalesData from "./data/getSalesData";

const DashboardPage = () => {
  const [startDate, setStartDate] = useState(subDays(new Date(), 6));

  const [endDate, setEndDate] = useState(new Date());

  const [companyStore, setCompanyStore] = useState("");

  const [categorizedSales, setCs] = useState([]);

  const dispatch = useDispatch();
  const salesSummary = useSelector((state) => state.company.salesSummary);
  const [chartData, setChartData] = useState({});

  console.log(chartData);

  const fetchData = () => {
    request("dashboard", {
      from: format(startDate, "yyyy-MM-dd"),
      to: format(endDate, "yyyy-MM-dd"),
      companyStore,
    })
      .then((res) => {
        if (res.summary) dispatch(setSummary(res.summary));
        if (res) setChartData(getSalesData(res));

        if (res.categorized_invoice_items) {
          setCs(
            Object.entries(res.categorized_invoice_items).map((item, idx) => {
              if (item.length > 1) {
                const { product = null } = item[1];
                return {
                  ...item[1],
                  web_visible:
                    product && product.image && product.web_visible
                      ? "Yes"
                      : "--",
                  id: idx,
                };
              } else {
                return false;
              }
            })
          );
        }
        // console.table(res.dates);
      })
      .catch((e) => {
        console.log(e);
      });
  };

  useEffect(() => {
    fetchData();
  }, []);

  const onChange = (dates) => {
    const [start, end] = dates;
    setStartDate(start);
    setEndDate(end);
  };

  return (
    <>
      {salesSummary.today && (
        <CardGrid
          list={[
            {
              title: "Today's Customers",
              currentSale: salesSummary.customers_today,
              pastSale: salesSummary.customers_past_today,
              currency: false,
            },
            {
              title: "Today's Sale",
              currentSale: salesSummary.today,
              pastSale: salesSummary.past_today,
            },
            {
              title: "Yesterday's Sale",
              currentSale: salesSummary.yesterday,
              pastSale: salesSummary.past_yesterday,
            },
            {
              title: "WTD Sale",
              currentSale: salesSummary.wtd,
              pastSale: salesSummary.past_wtd,
            },
            {
              title: "MTD Sale",
              currentSale: salesSummary.mtd,
              pastSale: salesSummary.past_mtd,
            },
            {
              title: "YTD Sale",
              currentSale: salesSummary.ytd,
              pastSale: salesSummary.past_ytd,
            },
          ]}
        />
      )}

      <div className="row">
        <div className="col-sm-12">
          <LineChart data={chartData}>
            <div
              className={
                "bg-white mb-3 d-flex align-items-center justify-content-center"
              }
            >
              <select
                value={companyStore}
                onChange={(e) => setCompanyStore(e.target.value)}
                className={`form-control ${s.select}`}
              >
                <option value="">All Stores</option>
                <option value="1">Nugegoda Store</option>
                <option value="2">Ragama Store</option>
                <option value="3">Kandy Store</option>
              </select>
              <div className={s.wrapper}>
                <DatePicker
                  selected={startDate}
                  onChange={onChange}
                  startDate={startDate}
                  endDate={endDate}
                  selectsRange
                  monthsShown={2}
                  className="form-control w-100"
                  styles={{ minWidth: "200px" }}
                />
              </div>
              <button className="ms-2 btn btn-primary" onClick={fetchData}>
                Update
              </button>
            </div>
          </LineChart>

          <br />

          <Datatable
            title={"Products Summary"}
            columns={columns}
            data={categorizedSales}
            defaultSortFieldId={"sale"}
            defaultSortAsc={false}
          />
        </div>
      </div>
    </>
  );
};

export default DashboardPage;
