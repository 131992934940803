import { LOGIN, LOGOUT } from "./types";

export const initialState = {
  isLoggedIn: false,
  data: {},
  pos_unit_items: [],
  web_unit_items: [],
  product_categories: [],
};

const userReducer = (state = initialState, action) => {
  switch (action.type) {
    case LOGIN:
      localStorage.setItem("accessToken", action.accessToken);
      return {
        ...state,
        isLoggedIn: true,
        data: action.user,
        pos_unit_items: action.pos_unit_items,
        web_unit_items: action.web_unit_items,
        product_categories: action.product_categories,
      };
    case LOGOUT:
      localStorage.removeItem("accessToken");
      return {
        ...state,
        isLoggedIn: false,
        data: {},
      };
    default:
      return { ...state };
  }
};

export default userReducer;
