import React from "react";
import { Switch, Route } from "react-router-dom";
import AuthRoutes from "./AuthRoutes";
import PublicRoutes from "./PublicRoutes";

class MainRouter extends React.Component {
    render() {
        return (
            <Switch>
                <Route path="/dashboard" component={AuthRoutes} />
                <Route path="/" component={PublicRoutes} />
            </Switch>
        );
    }
}

export default MainRouter;
