import { useEffect, useState } from "react";
import request from "../../../services/request";
import OrdersTable from "./OrdersTable";
import { format, parseISO } from "date-fns";

const tabs = [
  {
    label: "Pending Orders",
    slug: "pending",
    status: "Pending",
  },
  {
    label: "Billed Orders",
    slug: "billed",
    status: "Order Ready",
  },
  {
    label: "Delivered",
    slug: "delivered",
    status: "Delivered",
  },
];

const WebOrdersPage = () => {
  const [orders, setOrders] = useState([]);
  const [activeTab, setActiveTab] = useState(tabs[0].slug);
  useEffect(() => {
    fetchOrders();
  }, []);

  const fetchOrders = () => {
    request("web-orders/list")
      .then((res) => {
        if (res.orders && res.orders.length > 0) {
          setOrders(res.orders);
        }
      })
      .catch((e) => {
        console.log(e);
      });
  };

  // const defaultFinal = {
  //   June: {
  //     online: 0,
  //     failed: 0,
  //     cash: 0,
  //   },
  //   July: {
  //     online: 0,
  //     failed: 0,
  //     cash: 0,
  //   },
  //   August: {
  //     online: 0,
  //     failed: 0,
  //     cash: 0,
  //   },
  //   September: {
  //     online: 0,
  //     failed: 0,
  //     cash: 0,
  //   },
  // };
  //
  // const [final, setFinal] = useState(defaultFinal);
  //
  // useEffect(() => {
  //   if (!orders || orders.length === 0) return;
  //
  //   let temp = defaultFinal;
  //
  //   for (let i = 0; i < orders.length; i++) {
  //     const order = orders[i];
  //     const date = parseISO(order.created_at);
  //     let method = "failed";
  //     if (order.payment_method === "Online Payment") {
  //       if (order.payment_status !== "Pending") {
  //         method = "online";
  //       }
  //     } else {
  //       method = "cash";
  //     }
  //     const month = format(date, "MMMM");
  //     temp[month][method] = temp[month][method] + 1;
  //   }
  //   setFinal(temp);
  // }, [orders]);

  return (
    <div>
      <div className="row">
        <div className="col-sm-12">
          <div className="bg-white">
            <div className="btn-group" role="group">
              {tabs.map(({ label, slug, status }, idx) => {
                return (
                  <button
                    className={`btn ${
                      activeTab === slug ? "btn-success" : "btn-light"
                    }`}
                    onClick={() => setActiveTab(slug)}
                    key={idx}
                  >
                    {label}
                  </button>
                );
              })}
            </div>
            <OrdersTable
              orders={orders}
              status={tabs.find(({ slug }) => slug === activeTab).status}
            />
          </div>
        </div>
      </div>
    </div>
  );
};

export default WebOrdersPage;
