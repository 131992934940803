import currencyFormat from "utils/currencyFormat";
import { TiArrowSortedUp, TiArrowSortedDown } from "react-icons/ti";

const CardGrid = ({ list = [] }) => {
  if (!list || list.length === 0) return null;
  return (
    <div className="row">
      {list.map(
        (
          {
            title,
            currency = true,
            currentSale: { total: currentTotal, store_data },
            pastSale: { total: pastTotal, store_data: past_store_data },
          },
          idx
        ) => {
          const { gain, gainClass, validGain } = calculateGain(
            currentTotal,
            pastTotal
          );

          return (
            <div className="col-sm-6 col-xl-4 mb-3" key={idx}>
              <div className="card">
                <div className="card-body">
                  <h5 className="card-title text-dark fs-3">
                    {currency
                      ? currencyFormat(currentTotal, currentTotal > 100000)
                      : currentTotal}
                  </h5>
                  <h6 className="card-subtitle mb-2 text-muted d-flex w-100 align-items-center">
                    {title}

                    <span className={`ms-auto ${gainClass}`}>
                      {validGain ? gain + "%" : "–"}
                      {gain > 0 ? (
                        <TiArrowSortedUp />
                      ) : Number(gain) === 0 ? (
                        ""
                      ) : (
                        <TiArrowSortedDown />
                      )}
                    </span>
                  </h6>
                  {store_data && store_data.length > 0 && (
                    <>
                      <hr />
                      <table className={"table table-sm"}>
                        <thead>
                          <tr>
                            <th>Store</th>
                            <th className={"text-end"}>Sale</th>
                            <th />
                          </tr>
                        </thead>
                        <tbody>
                          {store_data.map(({ name, value }, idx) => {
                            const pastData = past_store_data[idx];
                            const { gain, gainClass, validGain } =
                              calculateGain(value, pastData && pastData.value);

                            return (
                              <tr key={idx}>
                                <td>
                                  <small className={"text-nowrap"}>
                                    {name}
                                  </small>
                                </td>
                                <td className={"text-end"}>
                                  <small className={"text-muted"}>
                                    {currency
                                      ? currencyFormat(
                                          Number(value),
                                          Number(value) > 1000
                                        )
                                      : value}
                                  </small>
                                </td>
                                <td className={"text-end"}>
                                  <small className={gainClass}>
                                    {validGain ? gain + "%" : "-"}
                                    {gain > 0 ? (
                                      <TiArrowSortedUp />
                                    ) : Number(gain) === 0 ? (
                                      ""
                                    ) : (
                                      <TiArrowSortedDown />
                                    )}
                                  </small>
                                </td>
                              </tr>
                            );
                          })}
                        </tbody>
                      </table>
                    </>
                  )}
                </div>
              </div>
            </div>
          );
        }
      )}
    </div>
  );
};

export default CardGrid;

const calculateGain = (current, past) => {
  let gain = 0;
  let gainClass = "";

  if (past && past > 0) {
    gain = (((current - past) * 100) / past).toFixed(2);
    if (gain > 0) {
      gainClass = "text-green";
    } else if (gain < 0) {
      gainClass = "text-red";
    }
  } else {
    gain = "0.00";
  }

  return {
    gain,
    gainClass,
    validGain: past && past > 0,
  };
};
