const createImage = async (file) => {
  let img = document.createElement("img");

  img.src = await new Promise((resolve) => {
    let reader = new FileReader();
    reader.onload = (e) => resolve(e.target.result);
    reader.readAsDataURL(file);
  });
  await new Promise((resolve) => (img.onload = resolve));

  let canvas = document.createElement("canvas");

  let ctx = canvas.getContext("2d");
  ctx.drawImage(img, 0, 0);

  const MAX_WIDTH = 300;
  const MAX_HEIGHT = 300;
  let width = img.width;
  let height = img.height;

  if (width > height) {
    if (width > MAX_WIDTH) {
      height *= MAX_WIDTH / width;
      width = MAX_WIDTH;
    }
  } else {
    if (height > MAX_HEIGHT) {
      width *= MAX_HEIGHT / height;
      height = MAX_HEIGHT;
    }
  }
  canvas.width = width;
  canvas.height = height;
  ctx.drawImage(img, 0, 0, width, height);

  return canvas.toDataURL("image/webp");
};

export default createImage;
