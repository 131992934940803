import LoginLayout from "./LoginLayout";
import { useEffect } from "react";
import request from "../../../services/request";
import { useHistory } from "react-router-dom";
import { useDispatch } from "react-redux";
import { login } from "../../../Stores/UserStore/actions";

const Validate = ({ token = "", setIsGuest = () => null }) => {
  const history = useHistory();
  const dispatch = useDispatch();

  useEffect(() => {
    if (!localStorage.getItem("accessToken")) {
      setIsGuest();
      return;
    }
    request("dashboard-data")
      .then((res) => {
        const {
          data,
          user,
          error,
          pos_unit_items,
          web_unit_items,
          product_categories,
        } = res;
        if (user && data) {
          dispatch(
            login(
              user,
              token,
              pos_unit_items,
              web_unit_items,
              product_categories
            )
          );
          const searchParams = new URLSearchParams(window.location.search);
          const redirectTo = searchParams.get("redirectTo") || "/dashboard";
          history.push(redirectTo);
        } else {
          console.log(error);
          setIsGuest(true);
        }
      })
      .catch((e) => {
        setIsGuest();
      });
  }, []);

  return null;
  return (
    <LoginLayout>
      <p>Authenticating...</p>
      <div className="spinner-border" role="status" />
    </LoginLayout>
  );
};

export default Validate;
