import { useState } from "react";
import Button from "react-bootstrap/Button";
import Modal from "react-bootstrap/Modal";
import Form from "react-bootstrap/Form";
import Row from "react-bootstrap/Row";
import Col from "react-bootstrap/Col";
import Image from "react-bootstrap/Image";
import request from "../../../../services/request";
import { useSelector } from "react-redux";
import createImage from "./createImage";
import TextField from "./TextField";
import Select from "./Select";

const ProductForm = ({
  product = {},
  close = () => null,
  setProducts = () => null,
  refresh = () => null,
}) => {
  const pos_unit_items = useSelector((state) => state.user.pos_unit_items);
  const web_unit_items = useSelector((state) => state.user.web_unit_items);
  const product_categories = useSelector(
    (state) => state.user.product_categories
  );

  const {
    buying_price = "",
    compare_at_price = "",
    description = "",
    id = null,
    image = "",
    loyalty_member_price = "",
    name = "",
    other_names = "",
    pos_unit_id = "",
    pos_visible = false,
    product_category_id = "",
    remarks = "",
    selling_price = "",
    web_unit_id = "",
    web_visible = false,
  } = product || {};
  const [newProduct, setNewProduct] = useState({
    buying_price,
    compare_at_price,
    description,
    image,
    loyalty_member_price,
    name,
    new_image: null,
    id,
    other_names,
    pos_unit_id,
    pos_visible,
    product_category_id,
    remarks,
    selling_price,
    web_unit_id,
    web_visible,
  });

  const setValue = (id, value) => {
    setNewProduct({
      ...newProduct,
      [id]: value,
    });
  };

  const processImage = async (e) => {
    let files = e.target.files || e.dataTransfer.files;

    if (!files.length) return;

    const processedImage = await createImage(files[0]);
    setValue("new_image", processedImage);
  };

  const handleSubmit = () => {
    request(
      `products/${newProduct.id || ""}`,
      newProduct,
      newProduct.id ? "patch" : "post"
    )
      .then((res) => {
        refresh();
        close();
      })
      .catch((e) => {
        console.log(e);
      });
  };

  let isProductReady =
    newProduct.name &&
    newProduct.name.length > 3 &&
    newProduct.buying_price &&
    newProduct.selling_price &&
    newProduct.product_category_id &&
    newProduct.pos_unit_id;

  if (newProduct.web_visible) {
    const imageAvailable = newProduct.new_image || newProduct.image;
    isProductReady = isProductReady && newProduct.web_unit_id && imageAvailable;
  }

  return (
    <Form>
      <Modal.Body>
        <Row>
          <Col sm={6}>
            <TextField
              value={newProduct.name}
              setValue={setValue}
              id={"name"}
              label={"Product name *"}
            />
            <TextField
              value={newProduct.buying_price}
              setValue={setValue}
              id={"buying_price"}
              type={"number"}
              label={"Buying price *"}
            />
            <TextField
              value={newProduct.selling_price}
              setValue={setValue}
              id={"selling_price"}
              label={"Selling price *"}
              type={"number"}
            />
            <TextField
              value={newProduct.compare_at_price}
              setValue={setValue}
              id={"compare_at_price"}
              label={"Compare at price"}
            />
            <TextField
              value={newProduct.other_names}
              setValue={setValue}
              id={"other_names"}
              label={"Other names"}
            />
            <TextField
              value={newProduct.description}
              setValue={setValue}
              id={"description"}
              label={"Description"}
            />
            <TextField
              value={newProduct.remarks}
              setValue={setValue}
              id={"remarks"}
              label={"Remarks"}
            />
          </Col>
          <Col sm={6}>
            <Select
              value={newProduct.product_category_id}
              setValue={setValue}
              options={product_categories.map((cat) => {
                return {
                  label: cat.name,
                  value: cat.id,
                };
              })}
              id={"product_category_id"}
              label={"Category *"}
            />
            <Select
              value={newProduct.pos_unit_id || ""}
              setValue={setValue}
              options={pos_unit_items.map(
                ({ id, measure, label, measure_after_label }) => {
                  return {
                    value: id,
                    label: !measure_after_label
                      ? `${measure} ${label}`
                      : `${label} ${measure}`,
                  };
                }
              )}
              id={"pos_unit_id"}
              label={"POS Item Unit *"}
            />

            <hr className={"my-4"} />

            <Form.Group className="mb-3" controlId="web_visible">
              <Form.Check
                type={"checkbox"}
                id={`web_visible`}
                checked={newProduct.web_visible}
                label={`Display on the website`}
                onChange={(e) => setValue("web_visible", !!e.target.checked)}
              />
            </Form.Group>

            {!!newProduct.web_visible && (
              <>
                <Select
                  value={newProduct.web_unit_id || ""}
                  setValue={setValue}
                  id={"web_unit_id"}
                  label={"Web Item Unit"}
                  options={web_unit_items.map(
                    ({ id, measure, label, measure_after_label }) => {
                      return {
                        value: id,
                        label: !measure_after_label
                          ? `${measure} ${label}`
                          : `${label} ${measure}`,
                      };
                    }
                  )}
                />

                <Form.Group controlId="formFile" className="mb-3">
                  <Form.Label>Image</Form.Label>
                  <Form.Control type="file" onChange={processImage} />
                  {!!newProduct.image || newProduct.new_image ? (
                    <>
                      {image && (
                        <Form.Text className="text-muted d-block">
                          Selecting a new image to replace the existing file.
                        </Form.Text>
                      )}
                      <Image
                        src={newProduct.new_image || newProduct.image}
                        thumbnail
                        width={160}
                        className={"mt-3"}
                      />
                    </>
                  ) : (
                    ""
                  )}
                </Form.Group>
              </>
            )}
          </Col>
        </Row>
      </Modal.Body>
      <Modal.Footer>
        <Button variant="secondary" onClick={close}>
          Discard & Close
        </Button>
        <Button
          variant="primary"
          onClick={handleSubmit}
          disabled={!isProductReady}
        >
          {id ? "Save Changes" : "Add Product"}
        </Button>
      </Modal.Footer>
    </Form>
  );
};

export default ProductForm;
