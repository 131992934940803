import * as s from "./Chart.module.scss";
import { format, parse } from "date-fns";
import currencyFormat from "utils/currencyFormat";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const Chart = ({ data, children = "" }) => {
  const { chartData = [], maxCount = 20, maxValue = 1000 } = data;
  const countMaxValue = getMaxValue(maxCount, 10);
  const valueMaxValue = getMaxValue(maxValue, 100);

  console.log(chartData);

  return (
    <div className={s.chart_wrapper}>
      {children}
      <ResponsiveContainer width="100%" height="100%" className={s.chart_block}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          syncId="anyId"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis domain={[0, countMaxValue]} />
          <Tooltip content={<CustomTooltipForCount />} />
          <Line
            type="monotone"
            dataKey="countAll"
            stroke="#1dd1a1"
            strokeDasharray="3 3"
          />
          <Line type="monotone" dataKey="countCompleted" stroke="#10ac84" />
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" height="100%" className={s.chart_block}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          syncId="anyId"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis domain={[0, valueMaxValue]} />
          <Tooltip content={<CustomTooltipForValues />} />
          <Line
            type="monotone"
            dataKey="valueAll"
            stroke="#1dd1a1"
            strokeDasharray="3 3"
          />
          <Line type="monotone" dataKey="valueCompleted" stroke="#10ac84" />
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" height="100%" className={s.chart_block}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          syncId="anyId"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="month" />
          <YAxis domain={[0, valueMaxValue]} />
          <Tooltip content={<CustomTooltipForType />} />
          <Line type="monotone" dataKey="onlinePayment" stroke="#3498db" />
          <Line type="monotone" dataKey="cashOnDelivery" stroke="#10ac84" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default Chart;

const NA = "N/A";

function CustomTooltipForType({ payload, label, active }) {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <div className={s.custom_tooltip}>
        <span>{data.month}</span>
        <p className="intro d-flex">
          Online Payments - {currencyFormat(data.onlinePayment)}
          <br />
          Cash on Delivery - {currencyFormat(data.cashOnDelivery)}
        </p>
      </div>
    );
  }

  return null;
}

function CustomTooltipForCount({ payload, label, active }) {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <div className={s.custom_tooltip}>
        <span>{data.month}</span>
        <p className="intro d-flex">
          Billed Web Orders - {data.countCompleted}
          <br />
          Total Web Orders - {data.countAll}
        </p>
      </div>
    );
  }

  return null;
}

function CustomTooltipForValues({ payload, label, active }) {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    return (
      <div className={s.custom_tooltip}>
        <span>{data.month}</span>
        <p className="intro d-flex">
          Billed Web Orders - {currencyFormat(data.valueCompleted)}
          <br />
          Total Web Orders - {currencyFormat(data.valueAll)}
        </p>
      </div>
    );
  }

  return null;
}

const getMaxValue = (value, round = 100) => {
  const t = Math.ceil(value / 5 / round) * round;
  return Math.ceil(value / t) * t;
};
