import { LOGIN, LOGOUT } from "./types";

export const login = (
  user,
  accessToken,
  pos_unit_items,
  web_unit_items,
  product_categories
) => {
  return {
    type: LOGIN,
    user,
    accessToken,
    pos_unit_items,
    web_unit_items,
    product_categories,
  };
};

export const logout = () => {
  return {
    type: LOGOUT,
  };
};
