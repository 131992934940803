import { useHistory } from "react-router-dom";
import { useDispatch, useSelector } from "react-redux";
import { useEffect, useState } from "react";
import request from "../../../services/request";
import { login } from "../../../Stores/UserStore/actions";
import LoginLayout from "./LoginLayout";

const LoginForm = () => {
  const history = useHistory();
  const dispatch = useDispatch();

  const isLoggedIn = useSelector((state) => state.user.isLoggedIn);

  const [username, setUsername] = useState("");
  const [password, setPassword] = useState("");

  const [error, setError] = useState("");

  const [progress, setProgress] = useState(false);

  useEffect(() => {
    setError("");
  }, [username, password]);

  const handleLogin = async (e) => {
    e.preventDefault();
    if (!username || !password || username.length < 5 || password.length < 5)
      return;
    setProgress(true);
    await request("dashboard-auth", { username, password })
      .then((res) => {
        const {
          user,
          token,
          error,
          pos_unit_items,
          web_unit_items,
          product_categories,
        } = res;
        if (error) {
          setError(error);
          setProgress(false);
        } else if (user && token) {
          dispatch(
            login(
              user,
              token,
              pos_unit_items,
              web_unit_items,
              product_categories
            )
          );
        }
        setProgress(false);
      })
      .catch((e) => {
        console.log(e);
        setProgress(false);
      });
    setProgress(false);
  };

  useEffect(() => {
    if (!isLoggedIn) return;
    setTimeout(() => {
      history.push("/dashboard");
    }, 50);
  }, [isLoggedIn]);
  return (
    <LoginLayout>
      <h1>Welcome back</h1>
      <form action="#" onSubmit={handleLogin} method={"post"}>
        <div className="input-group mb-3">
          <span className="input-group-text">Username</span>
          <input
            type="text"
            className={"form-control"}
            value={username}
            onChange={(e) => setUsername(e.target.value)}
          />
        </div>
        <div className="input-group mb-3">
          <span className="input-group-text">Password</span>
          <input
            type="password"
            className={"form-control"}
            value={password}
            onChange={(e) => setPassword(e.target.value)}
          />
        </div>
        <button
          className="btn btn-primary d-block w-100"
          type={"submit"}
          disabled={
            progress ||
            !username ||
            !password ||
            password.length < 5 ||
            username.length < 5
          }
        >
          {progress ? (
            <span
              className="spinner-border spinner-border-sm"
              role="status"
              aria-hidden="true"
            />
          ) : (
            <span>Login</span>
          )}
        </button>
        {error && <p className="text-danger mt-3 mb-0">{error}</p>}
      </form>
    </LoginLayout>
  );
};

export default LoginForm;
