import { useEffect } from "react";
import request from "services/request";
import { useDispatch } from "react-redux";
import { logout } from "../../../Stores/UserStore/actions";
import LoginLayout from "../../Public/LoginPage/LoginLayout";

const LogoutPage = () => {
  const dispatch = useDispatch();

  useEffect(() => {
    request("dashboard-de-auth")
      .then((res) => {
        dispatch(logout());
        window.location.href = "/";
      })
      .catch((e) => {
        console.log(e);
        window.location.href = "/";
      });
  }, [dispatch]);

  return (
    <LoginLayout>
      <p>Logging out...</p>
      <div className="spinner-border" role="status" />
    </LoginLayout>
  );
};

export default LogoutPage;
