import currencyFormat from "utils/currencyFormat";
import { useState } from "react";
import { format, parseISO } from "date-fns";

const OrdersTable = ({ orders, status = "Pending" }) => {
  const filteredOrders = orders.filter((order) => order.status === status);

  const [order, setOrder] = useState(null);

  if (order && order.id) {
    const {
      first_name,
      last_name,
      company_id,
      created_at,
      delivery_city_id,
      id,
      invoice_id,
      items,
      phone,
      updated_at,
      customer,
      ...others
    } = order;

    const data = [];
    for (let key in others) {
      if (others.hasOwnProperty(key)) {
        if (
          typeof others[key] === "string" ||
          typeof others[key] === "number"
        ) {
          data.push({
            label: key,
            value: others[key],
          });
        }
      }
    }
    return (
      <div className={"py-4"}>
        <button
          className="btn btn-sm btn-outline-success mb-3"
          onClick={() => setOrder(null)}
        >
          Back
        </button>
        <h4>Order Details: #{order.id}</h4>
        <table className="table">
          <tbody>
            <tr>
              <td>Name</td>
              <td>
                {first_name} {last_name} {id}
              </td>
            </tr>
            <tr>
              <td>Phone</td>
              <td>
                <a href={`tel:${phone}`}>{phone}</a>
              </td>
            </tr>
            {data.map((d, idx) => (
              <tr key={idx}>
                <td>{d.label}</td>
                <td>{d.value}</td>
              </tr>
            ))}
          </tbody>
        </table>
      </div>
    );
  }
  return (
    <div>
      <table className={"table"}>
        <thead>
          <tr>
            <th>Name</th>
            <th className={"d-none d-md-table-cell"}>Placed at</th>
            <th>Phone</th>
            <th className={"d-none d-md-table-cell"}>City</th>
            <th className={"d-none d-md-table-cell"}>Total</th>
            <th className={"d-none d-md-table-cell"}>Method</th>
            <th></th>
          </tr>
        </thead>
        <tbody>
          {filteredOrders.map((order, idx) => {
            const {
              first_name,
              last_name,
              city,
              payment_method,
              total,
              phone,
              created_at,
            } = order;
            return (
              <tr key={idx}>
                <td>
                  {first_name} {last_name} {order.id}
                </td>
                <td>{format(parseISO(created_at), "MMM d, p")}</td>
                <td>
                  <a href={`tel:${{ phone }}`}>{phone}</a>
                </td>
                <td className={"d-none d-md-table-cell"}>{city}</td>
                <td className={"d-none d-md-table-cell"}>
                  {currencyFormat(total)}
                </td>
                <td className={"d-none d-md-table-cell"}>{payment_method}</td>
                <td>
                  <button
                    className="btn btn-sm btn-outline-success"
                    onClick={() => setOrder(order)}
                  >
                    View
                  </button>
                </td>
              </tr>
            );
          })}
        </tbody>
      </table>
    </div>
  );
};

export default OrdersTable;
