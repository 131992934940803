import { addMonths, setDate, format } from "date-fns";

const FORMAT = "MMM yy";

const getChartData = (data) => {
  const { allWebOrders, completedWebOrders } = data;
  if (!allWebOrders || !completedWebOrders) return {};
  const tempData = {};
  for (let i = 0; i < 12; i++) {
    const thisMonth = setDate(new Date(), 1);
    const currentMonth = addMonths(thisMonth, i * -1);
    tempData[format(currentMonth, FORMAT)] = {
      countAll: 0,
      countCompleted: 0,
      valueAll: 0,
      valueCompleted: 0,
      onlinePayment: 0,
      cashOnDelivery: 0,
    };
  }

  allWebOrders.forEach((order) => {
    const orderMonth = format(new Date(order.created_at), FORMAT);
    if (tempData[orderMonth]) {
      tempData[orderMonth].countAll += 1;
      tempData[orderMonth].valueAll += order.total;
    }
  });

  completedWebOrders.forEach((order) => {
    const orderMonth = format(new Date(order.created_at), FORMAT);
    // console.log(orderMonth);
    if (tempData[orderMonth]) {
      tempData[orderMonth].countCompleted += 1;
      tempData[orderMonth].valueCompleted += order.total;
      if (order.payment_method === "Online Payment") {
        tempData[orderMonth].onlinePayment += order.total;
      } else {
        tempData[orderMonth].cashOnDelivery += order.total;
      }
    }
  });

  const chartData = [];

  let maxCount = 0;
  let maxValue = 0;

  for (let i = 12; i >= 0; i--) {
    const thisMonth = setDate(new Date(), 1);
    const currentMonth = format(addMonths(thisMonth, i * -1), FORMAT);
    if (tempData[currentMonth]) {
      if (maxCount < tempData[currentMonth].countAll) {
        maxCount = tempData[currentMonth].countAll;
      }
      if (maxValue < tempData[currentMonth].valueAll) {
        maxValue = tempData[currentMonth].valueAll;
      }
      chartData.push({
        month: currentMonth,
        ...tempData[currentMonth],
      });
    }
  }

  return {
    chartData,
    maxCount,
    maxValue,
  };
};

export default getChartData;
