import React from "react";
import { Switch, Route } from "react-router-dom";

import UserLayout from "../Layouts/User";

import DashboardPage from "../Pages/User/DashboardPage";
import CustomersPage from "../Pages/User/CustomersPage";
import WebOrdersPage from "../Pages/User/WebOrdersPage";
import ProductsPage from "../Pages/User/ProductPages";
import InvoicesPage from "../Pages/User/InvoicePages";
import WebOrdersSummaryPage from "../Pages/User/WebOrdersSummaryPage";

class AuthRoutes extends React.Component {
  render() {
    return (
      <UserLayout>
        <Switch>
          <Route exact path="/dashboard/products" component={ProductsPage} />
          <Route exact path="/dashboard/invoices" component={InvoicesPage} />
          <Route exact path="/dashboard/web-orders" component={WebOrdersPage} />
          <Route
            exact
            path="/dashboard/web-orders-summary"
            component={WebOrdersSummaryPage}
          />
          <Route exact path="/dashboard/customers" component={CustomersPage} />
          <Route exact path="/dashboard" component={DashboardPage} />
        </Switch>
      </UserLayout>
    );
  }
}

export default AuthRoutes;
