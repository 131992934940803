import React from "react";
import { Switch, Route } from "react-router-dom";

import PublicLayout from "../Layouts/Public";

import LoginPage from "../Pages/Public/LoginPage";
import LogoutPage from "../Pages/User/LogoutPage";
// import RegisterPage from "../Pages/Public/RegisterPage";

class PublicRoutes extends React.Component {
  render() {
    return (
      <PublicLayout>
        <Switch>
          <Route exact path="/logout" component={LogoutPage} />
          <Route path="/" component={LoginPage} />
        </Switch>
      </PublicLayout>
    );
  }
}

export default PublicRoutes;
