import * as s from "./index.module.scss";
import { format, parse } from "date-fns";
import currencyFormat from "utils/currencyFormat";

import {
  LineChart,
  Line,
  XAxis,
  YAxis,
  CartesianGrid,
  Tooltip,
  ResponsiveContainer,
} from "recharts";

const CustomLineChart = ({
  data: { chartData = [], y1Max = 50000, y2Max = 100 },
  children,
}) => {
  const saleMaxValue = getMaxValue(y1Max, 10000);
  const customerMaxValue = getMaxValue(y2Max, 100);
  return (
    <div className={s.chart_wrapper}>
      {children}
      <ResponsiveContainer width="100%" height="100%" className={s.chart_block}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          syncId="anyId"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, saleMaxValue]} />
          <Tooltip content={<CustomTooltipForSales />} />
          <Line
            type="monotone"
            dataKey="saleAtPastDate"
            stroke="#1dd1a1"
            strokeDasharray="3 3"
          />
          <Line type="monotone" dataKey="saleAtDate" stroke="#10ac84" />
        </LineChart>
      </ResponsiveContainer>

      <ResponsiveContainer width="100%" height="100%" className={s.chart_block}>
        <LineChart
          width={500}
          height={300}
          data={chartData}
          syncId="anyId"
          margin={{
            top: 20,
            right: 30,
            left: 20,
            bottom: 20,
          }}
        >
          <CartesianGrid strokeDasharray="3 3" />
          <XAxis dataKey="name" />
          <YAxis domain={[0, customerMaxValue]} />
          <Tooltip content={<CustomTooltipForCustomers />} />
          <Line
            type="monotone"
            dataKey="pastCustomersAtDate"
            stroke="#1dd1a1"
            strokeDasharray="3 3"
          />
          <Line type="monotone" dataKey="customersAtDate" stroke="#10ac84" />
        </LineChart>
      </ResponsiveContainer>
    </div>
  );
};

export default CustomLineChart;

const NA = "N/A";

function CustomTooltipForSales({ payload, label, active }) {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    const currentDate = parse(data.date, "yyyy-MM-dd", new Date());
    const pastDate = parse(data.pastDate, "yyyy-MM-dd", new Date());
    const title = `${format(currentDate, "MMM d")} vs ${format(
      pastDate,
      "MMM d"
    )} (${format(currentDate, "iii")})`;
    const gain =
      data.saleAtPastDate > 0
        ? (
            ((data.saleAtDate - data.saleAtPastDate) / data.saleAtPastDate) *
            100
          ).toFixed(2)
        : NA;
    return (
      <div className={s.custom_tooltip}>
        <span>{title}</span>
        <p className="intro">
          {`${currencyFormat(data.saleAtDate)}`}{" "}
          <span
            className={
              gain === NA ? s.neutral : gain > 0 ? s.success : s.danger
            }
          >
            {gain}
          </span>
        </p>
      </div>
    );
  }

  return null;
}

function CustomTooltipForCustomers({ payload, label, active }) {
  if (active && payload && payload.length > 0) {
    const data = payload[0].payload;
    const currentDate = parse(data.date, "yyyy-MM-dd", new Date());
    const pastDate = parse(data.pastDate, "yyyy-MM-dd", new Date());
    const title = `${format(currentDate, "MMM d")} vs ${format(
      pastDate,
      "MMM d"
    )} (${format(currentDate, "iii")})`;
    const gain =
      data.pastCustomersAtDate > 0
        ? (
            ((data.customersAtDate - data.pastCustomersAtDate) /
              data.pastCustomersAtDate) *
            100
          ).toFixed(2)
        : NA;
    return (
      <div className={s.custom_tooltip}>
        <span>{title}</span>
        <p className="intro">
          {`${data.customersAtDate}`} Customers{" "}
          <span
            className={
              gain === NA ? s.neutral : gain > 0 ? s.success : s.danger
            }
          >
            {gain}
          </span>
        </p>
      </div>
    );
  }

  return null;
}

const getMaxValue = (value, round = 100) => {
  const t = Math.ceil(value / 5 / round) * round;
  return Math.ceil(value / t) * t;
};
