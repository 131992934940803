import Modal from "react-bootstrap/Modal";
import ProductForm from "./Form";

const ProductModal = ({
  product = {},
  show = false,
  close = () => null,
  setProducts = () => null,
  refresh = () => null,
}) => {
  const { id = null, name = "" } = product || {};
  const isNewProduct = id === null;
  return (
    <>
      <Modal
        show={show}
        onHide={close}
        backdrop="static"
        keyboard={false}
        size="lg"
      >
        <Modal.Header closeButton>
          <Modal.Title>
            {isNewProduct ? "Add new product" : `Edit product #${id} - ${name}`}
          </Modal.Title>
        </Modal.Header>
        <ProductForm
          product={product}
          close={close}
          setProducts={setProducts}
          refresh={refresh}
        />
      </Modal>
    </>
  );
};

export default ProductModal;
