import Form from "react-bootstrap/Form";

const TextField = ({
  value = "",
  id = "",
  setValue = () => null,
  type = "text",
  label = "",
  helpText = "",
}) => {
  const displayValue = type === "number" && value ? value / 100 : value || "";
  const handleChange = (e) => {
    if (type === "number") {
      setValue(id, e.target.value * 100);
    } else {
      setValue(id, e.target.value);
    }
  };
  return (
    <Form.Group className="mb-3" controlId={id}>
      <Form.Label>{label}</Form.Label>
      <Form.Control type={type} value={displayValue} onChange={handleChange} />
      {helpText && <Form.Text className="text-muted">{helpText}</Form.Text>}
    </Form.Group>
  );
};

export default TextField;
